
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        
































































































#login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 50px;
  flex: 1;

  .form {
    @include forms;

    a {
      text-decoration: none;
    }
  }

  .scale-enter-active,
  .scale-leave-active {
    transition: transform 0.2s ease;
  }

  .scale-enter,
  .scale-leave-to {
    transform: scale(0.8);
  }
}
