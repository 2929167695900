
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        









































#auth {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $intro-back;

  > #login-page,
  > #register-page {
    flex: 1;
  }
}
